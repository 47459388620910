import { useMutation, useQuery } from "@tanstack/react-query";
import { Row, Col, Descriptions, Table, Space, Button, message } from "antd";
import moment from "moment";
import { AlignType } from "rc-table/lib/interface";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { convertWithCommas } from "../../helpers/convertWithCommas";
import { creditFacilitiesService } from "../../services/credit-facilities";
import { shortTermLoanService } from "../../services/short-term-loan";
import { CreditFacility } from "../../types/admin-management";
import { CreateShortTermLoan, EditShortTermLoan, Memo, ShortTermLoanRecord } from "../../types/short-term-loan";
import { MainTable } from "./components";
import { LoanModal } from "./components/LoanModal/LoanModal";
import styles from "./ShortTermLoanPlan.module.css";
import { generateDownload, totalUsedPerFacilityCurrentDate } from "../../helpers";
import { DownloadOutlined } from "@ant-design/icons";

interface Props {}

const { Item } = Descriptions;
const { Summary } = Table;

export type MainTableRecord = {
  date: string;
  memo: string;
} & { [key: string]: number | string };

const verticalHeader: CreditFacility = {
  id: 0,
  name: "header",
  amount: 0,
  expiryDate: "Exp. Date",
  rate: "Rate"
};

export const ShortTermLoanPlan: FC<Props> = () => {
  const [visible, setVisible] = useState<{
    visible: boolean;
    payload: { name: string; date: string; id: string | number; recordId: number | string; amount: number } | undefined;
  }>({
    visible: false,
    payload: undefined
  });
  const {
    data: creditFacilities,
    isLoading: areCreditFacilitiesLoading,
    refetch: refetchFacilities
  } = useQuery(["credit-facilities"], () => creditFacilitiesService.getAllFacilities(false), { initialData: [] });

  const {
    data: shortTermLoanMemos,
    isLoading: areShortTermLoanMemosLoading,
    refetch: refetchMemos
  } = useQuery(["short-term-loan-memos"], () => shortTermLoanService.getAllMemos(), { initialData: [] });

  const {
    data: shortTermLoans,
    isLoading: areShortTermLoansLoading,
    refetch: refetchLoans
  } = useQuery(["short-term-loans"], () => shortTermLoanService.getAllShortTermLoans(), {
    initialData: [],
    enabled: !areShortTermLoanMemosLoading
  });

  const totalCredit = useMemo(() => creditFacilities.reduce((prev, curr) => (prev += curr.amount), 0), [creditFacilities]);

  const activeFacility = useMemo(
    () => shortTermLoans.filter((item) => item.active),

    [shortTermLoans]
  );
  const [auxActiveFacility, setAuxActiveFacility] = useState(shortTermLoans.filter((item) => item.active));

  useEffect(() => {
    setAuxActiveFacility(() => shortTermLoans.filter((item) => item.active));
  }, [shortTermLoans]);

  const totalUsedPerFacility = useMemo(
    () =>
      shortTermLoans
        .filter((item) => item.active)
        .reduce((prev: any, curr) => {
          const currentDate = new Date();
          const start = new Date(curr.startDate);
          const end = new Date(curr.endDate);
          // // console.log(
          //   "-=-=-CHECK PREV AND CURRENT",
          //   currentDate.getTime(),
          //   start.getTime(),
          //   end.getTime(),
          //   start.getTime() <= currentDate.getTime() && currentDate.getTime() < end.getTime()
          // );

          if (start.getTime() <= currentDate.getTime() && currentDate.getTime() < end.getTime()) {
            // // console.log("-=-=-=YES-=-=-=");

            return prev.hasOwnProperty(curr.creditFacilityName)
              ? { ...prev, [curr.creditFacilityName]: prev[curr.creditFacilityName] + curr.amount }
              : { ...prev, [curr.creditFacilityName]: curr.amount };
          } else {
            // // console.log("-=-=-=NO-=-=-=");
            return 0;
          }
        }, {}),
    [shortTermLoans]
  );

  const totalUsedAllFacility = () => {
    let total = 0;
    const currentDate = new Date();

    shortTermLoans
      .filter((item) => item.active)
      .forEach((item) => {
        const start = new Date(item.startDate);
        const end = new Date(item.endDate);
        if (start.getTime() <= currentDate.getTime() && currentDate.getTime() < end.getTime()) {
          total += item.amount;
        }
      });

    return total;
  };

  const availableLoan = useMemo(
    () => totalCredit - (Object.values(totalUsedPerFacility) as number[]).reduce((prev, curr) => (prev += curr), 0),
    [totalUsedPerFacility, totalCredit]
  );

  const [fullAvailableLoan, setFullAvailableLoan] = useState(availableLoan);

  useEffect(() => {
    setFullAvailableLoan(availableLoan);
  }, [availableLoan]);

  const onDeleteLoan = (value: number, id: number) => {
    // console.log("deleting", id);

    setAuxActiveFacility((oldValue) => oldValue.filter((af) => af.id !== id));
    setFullAvailableLoan((olValue) => olValue + value);
  };

  const columns = [verticalHeader, ...creditFacilities].map((item, index) => {
    return {
      key: index,
      dataIndex: item.name,
      title: item.name === "header" ? "" : () => <div style={{ textAlign: "center" }}>{item.name}</div>,
      width: item.name === "header" ? "10%" : 90 / creditFacilities.length + "%",
      align: "right" as AlignType
    };
  });

  const convertData = (data: CreditFacility[]) => {
    return data.reduce(
      (prev: any, curr: any) => {
        Object.entries(curr).forEach((entry) => {
          if (entry[0] === "amount") {
            prev[0][curr.name] = convertWithCommas(entry[1] as number);
          } else if (entry[0] === "rate") prev[1][curr.name] = entry[1];
          else if (entry[0] === "expiryDate") prev[2][curr.name] = moment(entry[1] as string).format("MM/DD/YYYY");
        });
        return prev;
      },
      [{ header: "Amount" }, { header: "Rate" }, { header: "Exp. Date" }]
    );
  };

  const { mutate: createShortTermLoan } = useMutation(
    ["create-short-term-loan"],
    (data: CreateShortTermLoan | EditShortTermLoan) =>
      !data.hasOwnProperty("id")
        ? shortTermLoanService.createShortTermLoan(data)
        : shortTermLoanService.editShortTermLoan(data as EditShortTermLoan),
    {
      onSuccess: (data, variables) => {
        message.success(`Successfully ${variables.hasOwnProperty("id") ? "edited" : "created"} short term loan."`);
        refetchLoans();
        setVisible({ visible: false, payload: undefined });
      }
    }
  );

  const { mutate: createMemo } = useMutation(["create-memo"], (data: Memo) => shortTermLoanService.createMemoForDate(data), {
    onSuccess: (data, variables) => {
      message.success(`Successfully added memo.`);
      refetchLoans();
      refetchMemos();
    }
  });
  const [realAvailableAmount, setRealAvailableAmount] = useState(totalCredit - totalUsedAllFacility());
  const convertMainTableData = useCallback(
    (data: ShortTermLoanRecord[]) => {
      return data.reduce((previous, current, index) => {
        const dateIndex = previous.findIndex((item: any) => moment(item.date).isSame(moment(current.startDate), "day"));
        if (dateIndex >= 0) {
          previous[dateIndex][current.creditFacilityName] = current.amount;
          previous[dateIndex][`${current.creditFacilityName}_id`] = current.id;
        } else {
          let memo = shortTermLoanMemos.filter((item: Memo) => moment(item.date).isSame(moment(current.startDate), "day"));
          // // console.log(current.id, "current.id");
          return [
            ...previous,
            {
              id: current.id,
              date: current.startDate,
              [current.creditFacilityName]: current.amount,
              [`${current.creditFacilityName}_id`]: current.id,
              loanSubtotal: current.loanSubTotal,
              balance: totalCredit - current.loanSubTotal,
              memo: memo.length ? memo[0].memo : ""
            }
          ];
        }

        return previous;
      }, [] as MainTableRecord[]);
    },
    [shortTermLoanMemos, realAvailableAmount]
  );

  const handleCellClick = (
    record: { name: string; date: string; id: string | number; recordId: number | string; amount: number } | undefined
  ) => {
    setVisible({ visible: true, payload: record });
  };

  const [convertedMainTableData, setConvertedMainTableData] = useState<MainTableRecord[]>(convertMainTableData(shortTermLoans));

  // console.log("auxActiveFacility", "====>", auxActiveFacility);

  const usedTotal = useCallback(
    (facility: string, loans: ShortTermLoanRecord[]) => {
      return convertWithCommas(totalUsedPerFacilityCurrentDate(facility, loans));
    },
    [auxActiveFacility]
  );

  // console.log("=-=-- CHECK totalUsedPerFacility-=-==-=-", totalCredit, totalUsedAllFacility());

  useEffect(() => {
    setConvertedMainTableData(convertMainTableData(shortTermLoans));
  }, [shortTermLoans, realAvailableAmount]);

  useEffect(() => {
    let count = 0;
    auxActiveFacility.forEach((item) => {
      const currentDate = new Date();
      const start = new Date(item.startDate);
      const end = new Date(item.endDate);

      if (start.getTime() <= currentDate.getTime() && currentDate.getTime() < end.getTime()) count += item.amount;
    });

    setRealAvailableAmount(totalCredit - count);
  }, [auxActiveFacility]);

  const handleDownload = async () => {
    try {
      const date = new Date().toISOString();
      message.loading({ content: "Downloading file...", key: "downloading" });

      const file = await shortTermLoanService.downloadShortTermLoan();
      generateDownload(file, `Short-Term-Loan-${date}.xlsx`, "excel");
      message.success({ content: "Success", key: "downloading" });
    } catch (err) {
      message.error({ content: err, key: "downloading" });
    }
  };

  return (
    <>
      <Row style={{ justifyContent: "flex-end", marginBottom: "18px" }}>
        <Button icon={<DownloadOutlined />} size="large" type="primary" onClick={handleDownload}>
          Download Excel
        </Button>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Table
            columns={columns}
            loading={areCreditFacilitiesLoading}
            dataSource={convertData(creditFacilities)}
            pagination={false}
            tableLayout="fixed"
            bordered
            className={styles.shortTermLoanTable}
            summary={(data) => {
              // // console.log(data);
              return (
                <Summary fixed="top">
                  <Summary.Row style={{ fontWeight: 700, background: "#f1f1f1" }}>
                    {Object.entries(data[0]).map((entry: any, index: number) =>
                      index === 0 ? (
                        <Summary.Cell index={0} align="right">
                          Used Total
                        </Summary.Cell>
                      ) : (
                        <Summary.Cell align="right" index={index}>
                          {usedTotal(entry[0], auxActiveFacility)}
                        </Summary.Cell>
                      )
                    )}
                  </Summary.Row>
                </Summary>
              );
            }}
          />
        </Col>
        <Col span={24} style={{ justifyContent: "space-between", display: "flex" }}>
          <Button type="primary" onClick={() => setVisible({ visible: true, payload: undefined })}>
            Add New Loan
          </Button>
          <Space style={{ justifyContent: "center" }}>
            <strong>Available Loan:</strong>
            <strong style={{ color: "green", fontSize: "1.1rem" }}>{"$" + convertWithCommas(realAvailableAmount)}</strong>
          </Space>
        </Col>
        <Col span={24}>
          <MainTable
            data={convertedMainTableData}
            handleCellClick={handleCellClick}
            handleMemoCreation={createMemo}
            loading={areShortTermLoansLoading || areShortTermLoanMemosLoading}
          />
        </Col>
        <LoanModal
          banksInfo={creditFacilities}
          setData={setConvertedMainTableData}
          facilities={creditFacilities}
          modalOptions={visible}
          setVisible={setVisible}
          handleSubmit={createShortTermLoan}
          loans={shortTermLoans}
          totalUsedPerFacility={totalUsedPerFacility}
          onDeleteLoan={(value: number, id: number) => onDeleteLoan(value, id)}
        />
      </Row>
    </>
  );
};
